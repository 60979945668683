import React from "react"
import PropTypes from "prop-types"
import "fontsource-libre-baskerville"
import "fontsource-montserrat"

const Layout = ({ children }) => {
  return (
    <>
      <div className="mx-auto my-0 max-w-screen-lg">
        {children}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
